import { Row, Col, Divider, Select, message, Popover } from "antd";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ClientContext from "../../../context/client/context";
import ClientContextProvider from "../../../context/client/provider";
import { getBooleanTypes } from "../../../models/client/enum";
import { IUpdateClient } from "../../../models/client/request";
import EndPoints from "../../../services/end-points";
import { languages } from "../../../utils/helpers/constants";
import FieldBuilder from "../../form-components/field-builder";
import MainForm from "../../form-components/main-form";
import Spin from "../../general/antd/spin";
import { MediumFor } from "../../../models/medium/enum";
import FormItem from "../../general/form-item";
import Controller from "../../form-components/controller";
import OldImage from "../../form-components/old-image";
import { IClientDetails } from "../../../models/client/response";
import { useForm } from "react-hook-form";
import styles from "./styles.module.scss";
import { useEffect } from "react";
import { INationality } from "../../../models/managent-collection/nationality/response";
import FileUploader from "../../form-components/file-uploader";
import office from "../../../assets/images/icon-files/office.jpg";
import a from "../../../assets/images/icon-files/pdf.png";

import { FaTrash } from "react-icons/fa";
import { getTranslations } from "../../../utils/helpers/translations";
const Form = () => {
  const [authorizationImage, setAuthorizationImage] = useState(undefined);
  const [commercialRecordFile, setCommercialRecordFile] = useState(undefined);
  const [nationalities, setNationalities] = useState<INationality[]>([]);
  const [nationalitiesLoading, setNationalitiesLoading] = useState(true);
  const [details, setDetails] = useState<IClientDetails>();
  const [detailsLoading, setDetailsLoading] = useState(true);
  const { id } = useParams();
  const { t } = useTranslation();
  const { actions } = useContext(ClientContext);
  const {
    control,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    const getNationalitiesLight = async () => {
      try {
        const { data } = await EndPoints.nationality.getAllNationality({
          perPage: -1,
        });
        setNationalities(data.data);
        setNationalitiesLoading(false);
      } catch (error) {
      } finally {
        setNationalitiesLoading(false);
      }
    };
    getNationalitiesLight();
  }, []);

  // get Details
  useEffect(() => {
    const getDetails = async () => {
      try {
        id &&
          (await EndPoints.client
            .getClient(Number(id))
            .then((res) => {
              setDetails(res.data);
              res.data.authorizationFile &&
                setAuthorizationImage(res?.data?.authorizationFile?.url);
              res.data.commercialRecordFile &&
                setCommercialRecordFile(res?.data?.commercialRecordFile?.url);
              res.data.type && setIsPersonal(res.data?.type);
            })
            .then(() => setDetailsLoading(false)));
      } catch (error) {
      } finally {
        setDetailsLoading(false);
      }
    };
    getDetails();
  }, []);

  const [isPersonal, setIsPersonal] = useState<number>(details?.type);

  const [typeFile, settypeFile] = useState(undefined);
  useEffect(() => {
    switch (details?.commercialRecordFile?.extension) {
      case "pdf":
        settypeFile(a);
        break;
      case "docx":
        settypeFile(office);
        break;

      default:
        settypeFile(commercialRecordFile);
        break;
    }
  }, [details?.name]);

  const [typeFileAuthorization, settypeFileAuthorization] = useState(undefined);
  useEffect(() => {
    switch (details?.authorizationFile?.extension) {
      case "pdf":
        settypeFileAuthorization(a);
        break;
      case "docx":
        settypeFileAuthorization(office);
        break;

      default:
        settypeFileAuthorization(authorizationImage);
        break;
    }
  }, [details?.name]);

  const types = [1, 2];

  return (
    <MainForm
      onSubmit={(data) => {
        data.legalForm = data.type == 2 ? data.legalForm : "مؤسسة  فردية ";
        details
          ? actions.updateClient(details?.id, data)
          : actions.createClient(data);
      }}
      title={details ? t("update_client") : t("create_client")}
      defaultValues={
        isPersonal == details?.type
          ? {
              fatherName: details?.fatherName,
              motherName: details?.motherName,
              nationalNumber: details?.nationalNumber,
              nationalityId: details?.nationality?.id,
              countryOfResidence:
                getTranslations(details?.translations?.countryOfResidence) ??
                null,
              cityOfResidence:
                getTranslations(details?.translations?.cityOfResidence) ?? null,
              name: getTranslations(details?.translations?.name) ?? null,
              responsiblePersonName: details?.responsiblPersonName,
              address: getTranslations(details?.translations?.address) ?? null,
              email: details?.email,
              landline: details?.landline,
              mobile: details?.mobile,
              note: details?.note,
              // commercialRrecord: details?.commercialRecord,
              authorization: details?.authorization,
              //  commercialRecordId: details?.commercialRecordFile?.id ?? null,
              authorizationId: details?.authorizationFile?.id ?? null,
              type: isPersonal,
              identityOfNumber: details?.identityOfNumber,
              honesty: details?.honesty,
              dateOfBirth: details?.dateOfBirth?.slice(0, 10) ?? null,
              dateOfIdentity: details?.dateOfIdentity?.slice(0, 10) ?? null,
              legalForm: details?.legalForm,
              telegramId: details?.telegramId,
              secondMobile: details?.secondMobile,
            }
          : ({
              type: isPersonal,
            } as IUpdateClient)
      }
    >
      {detailsLoading ? (
        <div style={{ height: "50vh" }}>
          <Spin />
        </div>
      ) : (
        <>
          <Row gutter={[16, 8]}>
            {languages?.map((lang, index) => {
              return (
                <Col span={8}>
                  <FieldBuilder
                    key={index}
                    name={`name[${lang?.code}]`}
                    label={`${t("name")} (${lang?.name})`}
                    input={{ type: "text" }}
                    rules={{ required: lang?.code == "ar" ? true : false }}
                  />
                </Col>
              );
            })}
            <Col span={8}>
              <FormItem label={`${t("type")}`} required={true}>
                <Controller
                  name="type"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: `${t("field_is_required_message")}`,
                    },
                  }}
                  render={({ field: { ...field } }) => {
                    return (
                      <>
                        <Select
                          {...field}
                          placeholder={`${t("type")}`}
                          className="shadow"
                          value={isPersonal}
                          style={{ width: "100%" }}
                          options={types.map((type, index) => {
                            return {
                              label: t(getBooleanTypes(type)) as string,
                              value: type as number,
                            };
                          })}
                          onChange={(e) => {
                            field.onChange(e);
                            setIsPersonal(e);
                          }}
                        ></Select>
                      </>
                    );
                  }}
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="legalForm"
                label={`${t("legal_form")}`}
                input={{ type: "hidden-type" }}
                disabled={isPersonal == 1 ? true : false}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="responsiblePersonName"
                label={`${t("responsibl_person_name")}`}
                input={{ type: "text" }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="fatherName"
                label={`${t("father_name")}`}
                input={{ type: "text" }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="motherName"
                label={`${t("mother_name")}`}
                input={{ type: "text" }}
              />
            </Col>

            <Col span={8}>
              <FieldBuilder
                name="nationalityId"
                label={`${t("nationality")}`}
                rules={{ required: true }}
                width="large"
                input={{
                  allowSearch: true,
                  loading: nationalitiesLoading,
                  type: "select",
                  options: nationalities.map((nationality, index) => {
                    return {
                      label: nationality?.name,
                      value: nationality?.id,
                    };
                  }),
                }}
              />
            </Col>
            {languages?.map((lang, index) => {
              return (
                <Col span={8}>
                  <FieldBuilder
                    key={index}
                    name={`countryOfResidence[${lang?.code}]`}
                    label={`${t("country_of_residence")} (${lang?.name})`}
                    input={{ type: "text" }}
                    rules={{ required: lang?.code == "ar" ? true : false }}
                  />
                </Col>
              );
            })}
            <Col span={8}>
              <FieldBuilder
                name="nationalNumber"
                label={`${t("national_number")}`}
                input={{ type: "text" }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="dateOfBirth"
                label={`${t("date_of_birth")}`}
                input={{ type: "date-picker", format: "YYYY-MM-DD" }}
                width="large"
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="identityOfNumber"
                label={`${t("identity_of_number")}`}
                input={{ type: "text" }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="dateOfIdentity"
                label={`${t("date_of_identity")}`}
                input={{ type: "date-picker", format: "YYYY-MM-DD" }}
                width="large"
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="honesty"
                label={`${t("honesty")}`}
                input={{ type: "text" }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="email"
                label={`${t("email")}`}
                input={{ type: "email" }}
                rules={{ required: true }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                rules={{ required: true }}
                name="mobile"
                label={`${t("mobile")}`}
                input={{ type: "text" }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                rules={{ required: false }}
                name="secondMobile"
                label={`${t("second_mobile")}`}
                input={{ type: "text" }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="landline"
                label={`${t("landline")}`}
                input={{ type: "text" }}
              />
            </Col>
            {languages?.map((lang, index) => {
              return (
                <Col span={8}>
                  <FieldBuilder
                    key={index}
                    name={`cityOfResidence[${lang?.code}]`}
                    label={`${t("city_of_residence")} (${lang?.name})`}
                    input={{ type: "text" }}
                    rules={{ required: lang?.code == "ar" ? true : false }}
                  />
                </Col>
              );
            })}
            {languages?.map((lang, index) => {
              return (
                <Col span={8}>
                  <FieldBuilder
                    name={`address[${lang?.code}]`}
                    label={`${t("address")} (${lang?.name})`}
                    input={{ type: "text" }}
                  />
                </Col>
              );
            })}
            <Col span={8}>
              <FieldBuilder
                name="telegramId"
                label={`${t("telegram")}`}
                input={{ type: "number" }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="note"
                label={`${t("note")}`}
                input={{ type: "text" }}
              />
            </Col>
          </Row>
          <Divider />
          {/*  <Row gutter={[16, 8]}>
            <Col span={8}>
              <FieldBuilder
                name="commercialRrecord"
                label={`${t("commercial_rrecord")}`}
                input={{ type: "text" }}
              />
            </Col>
          </Row>
          <Row gutter={[16, 8]}>
           
           <Col span={24}>
              <div>
                <FormItem label={t("commercial_rrecord_file")}>
                  <Controller
                    name="commercialRecordId"
                    rules={{
                      required: {
                        value: false,
                        message: `${t("field_is_required_message")}`,
                      },
                    }}
                    render={({ field: { ref, ...field } }) => {
                      return (
                        <FileUploader
                          {...field}
                          type="DRAGGER"
                          showUploadList
                          onChange={(response) => {
                            setCommercialRecordFile(undefined);
                            field.onChange(response?.id);
                          }}
                          fileFor={MediumFor.CLIENT_COMMERCIAL}
                        />
                      );
                    }}
                  />
                </FormItem>
              </div>
              {commercialRecordFile && (
                <Col span={5}>
                  <OldImage src={typeFile} />
                  <FaTrash
                    className={styles.trash}
                    onClick={() => setCommercialRecordFile(null)}
                  />
                </Col>
              )}
            </Col>
          </Row>
          <Divider /> */}

          <Row gutter={[16, 8]}>
            <Col span={8}>
              <FieldBuilder
                name="authorization"
                label={`${t("authorization")}`}
                input={{ type: "text" }}
                rules={{ required: true }}
              />
            </Col>
            {/* Upload authorization_file  */}
            <Col span={24}>
              <div>
                <FormItem label={t("authorization_file")} required>
                  <Controller
                    name="authorizationId"
                    rules={{
                      required: {
                        value: true,
                        message: `${t("field_is_required_message")}`,
                      },
                    }}
                    render={({ field: { ref, ...field } }) => {
                      return (
                        <FileUploader
                          {...field}
                          type="DRAGGER"
                          showUploadList
                          onChange={(response) => {
                            setAuthorizationImage(undefined);
                            field.onChange(response?.id);
                          }}
                          fileFor={MediumFor.CLIENT_AUTHORIZATION}
                        />
                      );
                    }}
                  />
                </FormItem>
              </div>
              {/* Old Active authorization_file*/}
              {authorizationImage && (
                <Col span={5}>
                  <OldImage src={typeFileAuthorization} />
                  <FaTrash
                    className={styles.trash}
                    onClick={() => setAuthorizationImage(null)}
                  />
                </Col>
              )}
            </Col>
            {/* <Col span={24}>
                                <FieldBuilder name='authorizationId' label={`${t('authorization_file')}`} input={{ type: 'file' }} />
                            </Col> */}
          </Row>
        </>
      )}
    </MainForm>
  );
};
const ClientForm = () => {
  return (
    <ClientContextProvider>
      <Form />
    </ClientContextProvider>
  );
};
export default ClientForm;
